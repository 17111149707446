/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* import Customers from "views/customers/Customers";
import Profile from "views/pages/examples/Profile.js";
import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Typography from "views/pages/components/Typography.js";
import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js";
import Categories from "views/pages/examples/Categories";
import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Calendar from "views/template/Calendar.js";
import Components from "views/template/forms/Components.js";

import Elements from "views/template/forms/Elements.js";
import Register from "views/users/Register.js";
import Timeline from "views/template/Timeline.js";
import Validation from "views/template/forms/Validation.js"; */
import Dashboard from "views/dashboards/Dashboard.js";
import Users from "views/users/Users";
import Dictionaries from "views/dictionary/Dics";
import Customers from "views/customers/Customers";
import Login from "views/users/Login.js";
import ConfirmEmail from "views/users/ConfirmEmail";

import ConfirmCustomerEmail from "views/customers/ConfirmCustomerEmail";
import ResetPassword from "views/users/ResetPassword";
import ConfirmPassword from "views/users/ConfirmPassword";
import ResetPasswordSuccess from "views/users/ResetPasswordSuccess";
import Lock from "views/users/Lock.js";
const routes = [
  {
    path: "/dashboard",
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  /*  {
    path: "/dashboard",
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Users",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/confirm-email/:id",
        name: "Confirm Email",
        miniName: "C",
        component: ConfirmEmail,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/reset-password",
        name: "Reset Password",
        miniName: "RP",
        component: ResetPassword,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/reset-password-success",
        name: "Reset Password Success",
        miniName: "RPS",
        component: ResetPasswordSuccess,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/confirm-password/:id",
        name: "Confirm Password",
        miniName: "CP",
        component: ConfirmPassword,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      }
    ],
  },
  {
    collapse: true,
    name: "Forms",
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        miniName: "E",
        component: Elements,
        layout: "/admin",
      },
      {
        path: "/components",
        name: "Components",
        miniName: "C",
        component: Components,
        layout: "/admin",
      },
      {
        path: "/validation",
        name: "Validation",
        miniName: "V",
        component: Validation,
        layout: "/admin",
      },
      {
        path: "/timeline",
        name: "Timeline",
        icon: "ni ni-bullet-list-67 text-dafault",
        component: Timeline,
        layout: "/admin",
        api: true,
        access: ["member", "admin", "creator"]
      },
      {
        path: "/calendar",
        name: "Calendar",
        icon: "ni ni-calendar-grid-58 text-red",
        component: Calendar,
        layout: "/admin",
        api: true,
        access: ["member", "admin", "creator"]
      },
    ],
  }, */
  {
    path: "/dictionary",
    name: "Dictionary",
    icon: "ni ni-circle-08 text-green",
    component: Dictionaries,
    layout: "/admin",
    api: true,
    access: ["admin", "creator"],
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-badge text-info",
    component: Users,
    layout: "/admin",
    api: true,
    access: ["admin"],
    views:[
      {
        path: "/confirm-email/:id",
        name: "Confirm Email",
        miniName: "C",
        component: ConfirmEmail,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/reset-password",
        name: "Reset Password",
        miniName: "RP",
        component: ResetPassword,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/reset-password-success",
        name: "Reset Password Success",
        miniName: "RPS",
        component: ResetPasswordSuccess,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/confirm-password/:id",
        name: "Confirm Password",
        miniName: "CP",
        component: ConfirmPassword,
        layout: "/auth",
        redirect: true
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      }
    ]
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-badge text-info",
    component: Customers,
    layout: "/admin",
    api: true,
    access: ["admin"],
  },
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    api: true,
    access: ["member"]
  },
  {
    path: "/confirm-email/:id",
    name: "Confirm Email",
    miniName: "C",
    component: ConfirmEmail,
    layout: "/customer",
    redirect: true
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    miniName: "RP",
    component: ResetPassword,
    layout: "/customer",
    redirect: true
  },
  {
    path: "/reset-password-success",
    name: "Reset Password Success",
    miniName: "RPS",
    component: ResetPasswordSuccess,
    layout: "/customer",
    redirect: true
  },
  {
    path: "/confirm-password/:id",
    name: "Confirm Password",
    miniName: "CP",
    component: ConfirmPassword,
    layout: "/customer",
    redirect: true
  },
];

export default routes;
