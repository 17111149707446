/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Modal
} from "reactstrap";
import moment from "moment";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import NotificationAlert from "react-notification-alert";


// core components
import AddCustomer from "./AddCustomer";
import { getCustomers, deleteCustomer } from "network/ApiAxios";


const Customers = props => {
  const { SearchBar } = Search;
  const [items, setItems] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [sizePerPage, setSizePerPage] = React.useState(5);
  const [totalSize, setTotalSize] = React.useState(0);
  const [sortField, setsortField] = React.useState();
  const [sortOrder, setsortOrder] = React.useState();

  const [addShow, setaddShow] = React.useState(false);
  const [editShow, seteditShow] = React.useState(false);
  const [tableShow, settableShow] = React.useState(true);
  const [custumerID, setcustumerID] = React.useState();

    
  const [notificationModal, setnotificationModal] = React.useState(false);
  const [deletecCustomerID, setdeleteCustomerID] = React.useState();
  const [deleteCustomerRow, setdeleteCustomerdRow] = React.useState();

  const notificationAlertRef = React.useRef(null);

  const showAdd = () => {
    setaddShow(true);
    settableShow(false);
  };
  const showEdit = () => {
    seteditShow(true);
    settableShow(false);
  };
  const showTable = () => {
    setaddShow(false);
    seteditShow(false);
    settableShow(true);
  };


  const buttonDeleteClick = (id, row) => {
    setdeleteCustomerID(id);
    setdeleteCustomerdRow(row);
    setnotificationModal(true);
  };

  const delCustomer= async () => {
    const { data } = await deleteCustomer(deletecCustomerID);
    if (data.success) {
      notify("success","Success","Delete Successfully");
      deleteCustomerRow.style = { display: "none" };
      setdeleteCustomerID(null);
      setdeleteCustomerdRow(null);
      setnotificationModal(false);
      fetchData(sizePerPage, page, sortField, sortOrder);
    } else {
      notify("error", "Error", data.msg );
    }
  };


  const beautfiyDate = (cell, row, rowIndex, formatExtraData) => {
    return <p>{moment(cell).format("DD/MM/YY")}</p>;
  };

  const bindActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          onClick={() => {
            setcustumerID(cell);
            showEdit();
          }}
          className="btn btn-neutral btn-xs"
          color="default"
          size="sm"
        >
          <i className="fa fa-edit"></i>
        </Button>

        <Button
          onClick={() => {
            buttonDeleteClick(cell, row);
          }}
          className="btn btn-danger btn-xs"
          color="default"
          size="sm"
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  };

  const onTableChange = (type, newState) => {
    setsortField("name");
    if (newState.sortField !== null) {
      setsortField(newState.sortField);
    }
    if (type === "pagination" || type === "sort") {
      setsortOrder(newState.sortOrder);
      fetchData(
        newState.sizePerPage,
        newState.page,
        sortField,
        newState.sortOrder,
        ""
      );
    } else if (type === "search") {
      console.log("passed 2");
      if (newState.searchText.length > 3)
        fetchData(
          newState.sizePerPage,
          newState.page,
          sortField,
          newState.sortOrder,
          newState.searchText
        );
    }
  };
  const colums = [
    { dataField: "userType", text: "Type", sort: true },
    { dataField: "name", text: "Name", sort: true, style: { width: "25%" } },
    { dataField: "email", text: "Email", sort: true },
    { dataField: "phone", text: "Phone", sort: false },
    { dataField: "phoneValidation", text: "Validation", sort: true },
    {
      dataField: "date",
      text: "Registered",
      sort: true,
      formatter: beautfiyDate,
    },
    {
      dataField: "_id",
      text: "",
      style: { textAlign: "right" },
      formatter: bindActionButtons,
    },
  ];
  const pagination = paginationFactory({
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    showTotal: true,
  });

  const fetchData = async (size, pagecount, field, order, search) => {
    if (size && pagecount) {
      size = sizePerPage;
      pagecount = page;
      field = sortField;
      order = sortOrder;
    }
    const { data } = await getCustomers(size, pagecount, field, order, search);
    if (data.success) {
      const { users: usersList } = data;
      const { totalSize: Size } = data;
      setItems(usersList);
      setPage(page);
      setSizePerPage(sizePerPage);
      setTotalSize(Size);
    } else {
      console.log(data.error);
    }
  };
  
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 20,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const { userType } = user;
      if (userType === "admin" || userType === "creator") {
        const fetchFirst = async () => {
          if (page && sizePerPage) {
            const { data } = await getCustomers(sizePerPage, page);
            const { users: usersList } = data;
            const { totalSize: Size } = data;
            setItems(usersList);
            setTotalSize(Size);
          }
        };
        fetchFirst();
      } else {
        props.history.push("/admin/dashboard");
      }
    } else {
      props.history.push("/admin/dashboard");
    }
  }, [setItems, setTotalSize, props.history, page, sizePerPage]);

  return (
    <>
    <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Customers
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6"></Col>
            </Row>
          </div>
        </Container>
      </div>
      {addShow && (
        <AddCustomer
          key="page_add"
          showTable={showTable}
          fetchAll={fetchData}
        />
      )}
      {editShow && (
        <AddCustomer
          key="page_edit"
          custumerID={custumerID}
          showTable={showTable}
          fetchData={fetchData}
        />
      )}
      {tableShow && (
        <>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0"> Customers</h3>
                </CardHeader>

                <ToolkitProvider
                  data={items}
                  keyField="_id"
                  columns={colums}
                  remote
                  search
                >
                  {props => (
                    <div className="p-4 table-responsive">
                      <div id="datatable-basic_filter" className="row pb-1">
                        <div className="col-6">
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <div className="col-6 text-right mb-10 mt-10">
                          <Button
                            onClick={showAdd}
                            className="btn btn-neutral btn-xs"
                            color="default"
                            size="md"
                          >
                            Add Customer
                          </Button>
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        remote
                        onTableChange={onTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={notificationModal}
            toggle={() => setnotificationModal(false)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your attention is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setnotificationModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i className="ni ni-bell-55 ni-3x" />
                <h4 className="heading mt-4">Are you sure?</h4>
                <p>
                  Item will be deleted permanently from the list.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => setnotificationModal(false)}
              >
                Close
              </Button>
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => delCustomer()}
              >
                Delete
              </Button>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Customers;
