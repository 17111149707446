/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import NotificationAlert from "react-notification-alert";
import {
  registerCustomer,
  getCustomer,
  editCustomer,
  confirmResetCustomer,
} from "network/ApiAxios";
import Select2 from "react-select2-wrapper";

const AddCustomer = props => {
  const ccID = React.useRef(props.customerID);
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailAddressFocus, setemailAddressFocus] = React.useState(false);
  const [phoneFocus, setphoneFocus] = React.useState(false);
  const [passwordFocus, setpasswordFocus] = React.useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = React.useState(false);

  const [customerID, setcustomerID] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [emailAddress, setemailAddress] = React.useState("");
  const [customerType, setcustomerType] = React.useState("");
  const [refID, setrefID] = React.useState("");
  const [affID, setaffID] = React.useState("");
  const [searchLimit, setsearchLimit] = React.useState("");

  const [password, setpassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const [toastMessage, setToastMessage] = React.useState(
    "Email sent! Check it to confirm your account. "
  );
  const notificationAlertRef = React.useRef(null);

  React.useEffect(() => {
    if (ccID) {
      const runAsync = async () => {
        const { data: info } = await getCustomer(ccID);
        console.log(info);
        setcustomerID(info.user._id);
        setName(info.user.name);
        setemailAddress(info.user.email);
        setPhone(info.phone);
        setaffID(info.user.marketing.affID);
        setrefID(info.user.marketing.refID);
        setcustomerType(info.user.userType);
        setsearchLimit(info.searchLimit);
      };
      runAsync();
    }
  }, [
    setcustomerID,
    setName,
    setemailAddress,
    setcustomerType,
    setaffID,
    setrefID,
    setsearchLimit,
  ]);



  const onPhoneChange = e => {
    console.log(e.target.value);
    setPhone(e.target.value);
  };

  const registerCus = async () => {
    if (!(name && emailAddress && password && confirmPassword)) {
      setToastMessage(
        "Make sure to fill all the inputs and agree to the privacy policy"
      );
      props.notify("danger", "Error!", toastMessage);
      return;
    }

    if (password !== confirmPassword) {
      props.setToastMessage("Passwords do not match");
      props.notify("danger", "Error!", toastMessage);
      return;
    }

    const response = await registerCustomer(
      name,
      emailAddress,
      password,
      phone,
      affID,
      customerType
    );
    const { data } = response;
    if (!data.success) {
      setError(data.msg);
      return;
    }
    setError("");
    setName("");
    setemailAddress("");
    setpassword("");
    setConfirmPassword("");
    setPhone("");
    setaffID("");
    setrefID("");
    props.notify("success", "Success!", toastMessage);
  };
  const editCus = async () => {
    const response = await editCustomer(
      customerID,
      name,
      emailAddress,
      password,
      phone,
      affID,
      customerType
    );
    const { data } = response;
    if (data.success) {
      props.notify("success", "Succes", toastMessage);
      /*  user = {...user, name, email}
        localStorage.setItem("user", JSON.stringify(user));
        props.history.push("/admin/users"); */
    } else {
      setToastMessage(data.msg);
      props.notify("danger", "Error!", toastMessage);
    }
  };
  const changePassword = async () => {
    if (password !== confirmPassword) {
      setToastMessage("Passwords do not match");
      props.notify("danger", "Error!", toastMessage);
      return;
    }
    const response = await confirmResetCustomer(customerID, password);
    const { data } = response;
    if (data.success) {
      props.notify("success", "Succes", toastMessage);
      /*  user = {...user, name, email}
        localStorage.setItem("user", JSON.stringify(user));
        props.history.push("/admin/users"); */
    } else {
      setToastMessage(data.msg);
      props.notify("danger", "Error!", toastMessage);
    }
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {customerID === "" ? (
        <>
          <Container className="mt--6" fluid>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">Register a customer</h3>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Select2
                                className="form-control"
                                defaultValue="1"
                                options={{
                                  placeholder: "Select Customer Type",
                                }}
                                data={[
                                  { id: "free", text: "Free" },
                                  { id: "paid", text: "Paid" },
                                  { id: "contributer", text: "Contributer" },
                                  { id: "disabled", text: "disabled" },
                                ]}
                                value={customerType}
                                onSelect={e => setcustomerType(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: nameFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-user" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Name"
                                  type="text"
                                  onFocus={e => setNameFocus(true)}
                                  onBlur={e => setNameFocus(false)}
                                  onChange={e => setName(e.target.value)}
                                  value={name}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: emailAddressFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-envelope" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Email address"
                                  type="email"
                                  onFocus={e => setemailAddressFocus(true)}
                                  onBlur={e => setemailAddressFocus(false)}
                                  onChange={e =>
                                    setemailAddress(e.target.value)
                                  }
                                  value={emailAddress}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: phoneFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-phone" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  type="phone"
                                  placeholder="Enter phone number"
                                  onChange={data => onPhoneChange(data)}
                                  onFocus={e => setphoneFocus(true)}
                                  onBlur={e => setphoneFocus(false)}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: passwordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Password"
                                  type="password"
                                  onFocus={e => setpasswordFocus(true)}
                                  onBlur={e => setpasswordFocus(false)}
                                  onChange={e => setpassword(e.target.value)}
                                  value={password}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: confirmPasswordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Confirm Password"
                                  type="password"
                                  onFocus={e => setConfirmPasswordFocus(true)}
                                  onBlur={e => setConfirmPasswordFocus(false)}
                                  onChange={e =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  value={confirmPassword}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {error ? (
                          <div className="text-muted font-italic">
                            <small>
                              error:{" "}
                              <span className="text-red font-weight-700">
                                {error}
                              </span>
                            </small>
                          </div>
                        ) : null}
                        <div className="text-muted">
                          <Button
                            className="mt-4"
                            color="info"
                            type="button"
                            onClick={registerCus}
                          >
                            Create Customer
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <Container className="mt--6" fluid>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">Update customer</h3>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Select2
                                className="form-control"
                                defaultValue="1"
                                options={{
                                  placeholder: "Select Customer Type",
                                }}
                                data={[
                                  { id: "free", text: "Free" },
                                  { id: "paid", text: "Paid" },
                                  { id: "contributer", text: "Contributer" },
                                  { id: "disabled", text: "disabled" },
                                ]}
                                value={customerType}
                                onSelect={e => setcustomerType(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: nameFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-user" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Name"
                                  type="text"
                                  onFocus={e => setNameFocus(true)}
                                  onBlur={e => setNameFocus(false)}
                                  onChange={e => setName(e.target.value)}
                                  value={name}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: emailAddressFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-envelope" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Email address"
                                  type="email"
                                  onFocus={e => setemailAddressFocus(true)}
                                  onBlur={e => setemailAddressFocus(false)}
                                  onChange={e =>
                                    setemailAddress(e.target.value)
                                  }
                                  value={emailAddress}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: phoneFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-phone" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  type="phone"
                                  placeholder="Enter phone number"
                                  onChange={data => onPhoneChange(data)}
                                  onFocus={e => setphoneFocus(true)}
                                  onBlur={e => setphoneFocus(false)}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-phone" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  type="texr"
                                  disabled="true"
                                  value={searchLimit}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-phone" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  type="texr"
                                  disabled="true"
                                  value={refID}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-phone" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  type="texr"
                                  disabled="true"
                                  value={affID}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {error ? (
                          <div className="text-muted font-italic">
                            <small>
                              error:{" "}
                              <span className="text-red font-weight-700">
                                {error}
                              </span>
                            </small>
                          </div>
                        ) : null}
                        <div className="text-muted">
                          <Button
                            className="mt-4"
                            color="info"
                            type="button"
                            onClick={editCus}
                          >
                            Update Customer
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Change Password</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Password
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: passwordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Password"
                                  type="password"
                                  onFocus={e => setpasswordFocus(true)}
                                  onBlur={e => setpasswordFocus(false)}
                                  onChange={e => setpassword(e.target.value)}
                                  value={password}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: confirmPasswordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Confirm Password"
                                  type="password"
                                  onFocus={e => setConfirmPasswordFocus(true)}
                                  onBlur={e => setConfirmPasswordFocus(false)}
                                  onChange={e =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  value={confirmPassword}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col className="text-right" xs="12">
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => {
                            props.showTable();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={changePassword}
                          size="md"
                        >
                          Change
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default AddCustomer;
