import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import ReactQuill from "react-quill";
import Select2 from "react-select2-wrapper";
// core components
import {
  addDic,
  getDic,
  editDic,
  uploadPics,
  deletePics,
} from "network/ApiAxios";

const AddDic = props => {
  const ctID = React.useRef(props.transactionID);

  const [stringTR, setstringTR] = React.useState("");
  const [stringTRstate, setstringTRstate] = React.useState(null);

  const [stringEN, setstringEN] = React.useState("");
  const [stringENstate, setstringENstate] = React.useState(null);

  const [meaningTR, setmeaningTR] = React.useState("");
  const [meaningEN, setmeaningEN] = React.useState("");

  const [dicType, setdicType] = React.useState("");
  const [dicTypeState, setdicTypeState] = React.useState(null);

  const [transID, setTransID] = React.useState("");


  const [pictures, setPictures] = React.useState([]);
  const [picturesUpload, setPicturesUpload] = React.useState([]);

  const [videos, setVideos] = React.useState([]);

  const [process, setProcess] = React.useState(false);

  React.useEffect(() => {
    if (ctID) {
      const fetchData = async () => {
        const { data } = await getDic(ctID);
        if (data.success) {
          setTransID(data.dic._id);
          setdicType(data.dic.dicType);
          setstringTR(data.dic.dicString);
          setstringEN(data.dic.transString);
          setmeaningTR(data.dic.dicMeaning);
          setmeaningEN(data.dic.transMeaning);
          setPictures(data.dic.dicImage);
          setPicturesUpload(data.dic.dicImage);
          setVideos(data.dic.dicVideo);
        }
      };
      fetchData();
    }
  }, [
    setdicType,
    setstringTR,
    setstringEN,
    setmeaningTR,
    setmeaningEN,
    setPictures,
    setPicturesUpload,
    setVideos,
  ]);



  const publishPost = async () => {
    if (!dicType || !stringTR || !stringEN) {
      if (!dicTypeState) setdicTypeState("invalid");
      if (!stringTR) setstringTR("invalid");
      if (!stringEN) setstringEN("invalid");
      console.log("failed!");
      console.log(dicType);
      props.notify("danger", "Fail!", "Inputs are empty!");
    } else {
      setdicType("valid");
      setstringTR("valid");
      setstringEN("valid");
      const { _id: id } = JSON.parse(localStorage.getItem("user"));
      const { data } = await addDic(
        id,
        dicType,
        stringTR,
        stringEN,
        meaningTR,
        meaningEN,
        "",
        "",
        ""
      );
      if (data.success) {
        props.notify("success", "Success!", "toastMessage");
        props.showTable(false);
        props.fetchData(
          props.sizePerPage,
          props.page,
          props.sortField,
          props.sortOrder,
          ""
        );
      } else {
        props.notify("danger", "Fail!", data.error);
      }
    }
  };
  const editPost = async () => {
    if (!dicType || !stringTR || !stringEN) {
      if (!dicTypeState) setdicTypeState("invalid");
      if (!stringTR) setstringTR("invalid");
      if (!stringEN) setstringEN("invalid");
      props.notify("danger", "Fail!", "Inputs are empty!");
    } else {
      setdicTypeState("valid");
      setstringTR("valid");
      setstringEN("valid");
      const { _id: id } = JSON.parse(localStorage.getItem("user"));

      const { data } = await editDic(
        transID,
        id,
        dicType,
        stringTR,
        stringEN,
        meaningTR,
        meaningEN,
        pictures,
        "",
        ""
      );
      if (data.success) {
        props.notify("success", "Success!", "toastMessage");
        props.showTable(false);
        props.fetchData(
          props.sizePerPage,
          props.page,
          props.sortField,
          props.sortOrder,
          ""
        );
      } else {
        props.notify("danger", "Fail!", data.error);
      }
    }
  };

  const uploadFile = e => {
    //onsole.log(e.target.files[0]);
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("file", e.target.files[i]);
    }
    setProcess(true);
    uploadData(formData);
  };
  function deleteFile(i) {
    const s = pictures.filter((item, index) => index !== i);
    setProcess(true);
    deletedData(pictures[i].split("/").pop());
    setPictures(s);
  }

  const uploadData = async formData => {
    const { data } = await uploadPics(formData).catch(e => console.log(e));
    if (data.success) {
      setPicturesUpload([...picturesUpload, data.images[0].Location]);
      setPictures([...pictures, data.images[0].Location]);
    }
    setProcess(false);
  };
  const deletedData = async keyImage => {
    const { data } = await deletePics(keyImage).catch(e => console.log(e));
    console.log(data);

    if (data.success) {
      console.success("deleted success");
    } else {
      console.error("deleted fail");
    }
    setProcess(false);
  };

  return (
    <>
      {alert}
      
      {transID === "" ? (
        <>
          <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <h3 className="mb-0">Add a translation</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <div
                    data-quill-placeholder="Quill WYSIWYG"
                    data-toggle="quill"
                  />
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="dicTypeStateVal"
                        >
                          Type
                        </label>
                        <Select2
                          className="form-control"
                          defaultValue={dicType}
                          id="dicTypeStateVal"
                          options={{
                            placeholder: "Select",
                          }}
                          data={[
                            { text: "Keyword", id: "Keyword" },
                            { text: "Slung", id: "Slung" },
                            { text: "Sentences", id: "Sentences" },
                          ]}
                          value={dicType}
                          onChange={e => {
                            setdicType(e.target.value);
                            setdicTypeState("valid");
                          }}
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please choose a type.
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="stringTRVal"
                        >
                          Turkish
                        </label>
                        <Input
                          style={{ marginBottom: 10 }}
                          id="stringTRVal"
                          placeholder="Turksih Keyword"
                          type="text"
                          valid={stringTRstate === "valid"}
                          invalid={stringTRstate === "invalid"}
                          onChange={e => {
                            setstringTR(e.target.value);
                            if (e.target.value !== "") {
                              setstringTRstate("valid");
                            }
                          }}
                          value={stringTR}
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please enter a keyword.
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="meaningTRVal"
                        >
                          Turkish Description
                        </label>
                        <ReactQuill
                          style={{ marginBottom: 10 }}
                          id="meaningTRVal"
                          value={meaningTR}
                          onChange={value => setmeaningTR(value)}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ["bold", "italic"],
                              ["blockquote", "code"],
                              [
                                {
                                  list: "ordered",
                                },
                                {
                                  list: "bullet",
                                },
                              ],
                            ],
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="stringENVal"
                        >
                          English
                        </label>
                        <Input
                          style={{ marginBottom: 10 }}
                          id="stringENVal"
                          placeholder="English Keyword"
                          type="text"
                          valid={stringENstate === "valid"}
                          invalid={stringENstate === "invalid"}
                          onChange={e => {
                            setstringEN(e.target.value);
                            if (e.target.value !== "") {
                              setstringENstate("valid");
                            }
                          }}
                          value={stringEN}
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please enter a keyword.
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="meaningENVal"
                        >
                          English Description
                        </label>
                        <ReactQuill
                          style={{ marginBottom: 10 }}
                          id="meaningENVal"
                          value={meaningEN}
                          onChange={value => setmeaningEN(value)}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ["bold", "italic"],
                              ["blockquote", "code"],
                              [
                                {
                                  list: "ordered",
                                },
                                {
                                  list: "bullet",
                                },
                              ],
                            ],
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="meaningENVal"
                        >
                          Pictures
                        </label>
                        <Row>
                          <Col lg="12" md="12">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="customFileLang"
                                type="file"
                                name="images"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={uploadFile}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileLang"
                              >
                                Select file
                              </label>
                            </div>
                          </Col>
                          <Col lg="12" md="12">
                            <Row>
                              {pictures.length > 0 &&
                                pictures.map((item, index) => {
                                  return (
                                    <Col
                                      md="3"
                                      lg="3"
                                      className="text-center"
                                      key={item}
                                    >
                                      <img
                                        className="img-thumbnail"
                                        src={item}
                                        alt=""
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-icon-only"
                                        onClick={e => deleteFile(index, e)}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col className="text-right">
                    {process ? (
                      <>
                        <Button color="primary" type="button">
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Loading...</span>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => {
                            props.showTable();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          onClick={publishPost}
                        >
                          Publish
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </>
      ) : (
        <>
          <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <h3 className="mb-0">Edit a translation</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <div
                    data-quill-placeholder="Quill WYSIWYG"
                    data-toggle="quill"
                  />
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="dicTypeStateVal"
                        >
                          Type
                        </label>
                        <Select2
                          className="form-control"
                          defaultValue={dicType}
                          id="dicTypeStateVal"
                          options={{
                            placeholder: "Select",
                          }}
                          data={[
                            { text: "Keyword", id: "Keyword" },
                            { text: "Slung", id: "Slung" },
                            { text: "Sentences", id: "Sentences" },
                          ]}
                          value={dicType}
                          onChange={e => {
                            setdicType(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="stringTRVal"
                        >
                          Turkish
                        </label>
                        <Input
                          style={{ marginBottom: 10 }}
                          id="stringTRVal"
                          placeholder="Turksih Keyword"
                          type="text"
                          valid={stringTRstate === "valid"}
                          invalid={stringTRstate === "invalid"}
                          onChange={e => {
                            setstringTR(e.target.value);
                            if (e.target.value !== "") {
                              setstringTRstate("valid");
                            }
                          }}
                          value={stringTR}
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please enter a keyword.
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="meaningTRVal"
                        >
                          Turkish Description
                        </label>
                        <ReactQuill
                          style={{ marginBottom: 10 }}
                          id="meaningTRVal"
                          value={meaningTR}
                          onChange={value => setmeaningTR(value)}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ["bold", "italic"],
                              ["blockquote", "code"],
                              [
                                {
                                  list: "ordered",
                                },
                                {
                                  list: "bullet",
                                },
                              ],
                            ],
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="stringENVal"
                        >
                          English
                        </label>
                        <Input
                          style={{ marginBottom: 10 }}
                          id="stringENVal"
                          placeholder="English Keyword"
                          type="text"
                          valid={stringENstate === "valid"}
                          invalid={stringENstate === "invalid"}
                          onChange={e => {
                            setstringEN(e.target.value);
                            if (e.target.value !== "") {
                              setstringENstate("valid");
                            }
                          }}
                          value={stringEN}
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">
                          Please enter a keyword.
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="meaningENVal"
                        >
                          English Description
                        </label>
                        <ReactQuill
                          style={{ marginBottom: 10 }}
                          id="meaningENVal"
                          value={meaningEN}
                          onChange={value => setmeaningEN(value)}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ["bold", "italic"],
                              ["blockquote", "code"],
                              [
                                {
                                  list: "ordered",
                                },
                                {
                                  list: "bullet",
                                },
                              ],
                            ],
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="meaningENVal"
                        >
                          English Description
                        </label>
                        <Row>
                          <Col lg="12" md="12">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="customFileLang"
                                type="file"
                                name="images"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={uploadFile}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileLang"
                              >
                                Select file
                              </label>
                            </div>
                          </Col>
                          <Col lg="12" md="12">
                            <Row>
                              {pictures.length > 0 &&
                                pictures.map((item, index) => {
                                  return (
                                    <Col
                                      md="3"
                                      lg="3"
                                      className="text-center"
                                      key={item}
                                    >
                                      <img
                                        className="img-thumbnail"
                                        src={item}
                                        alt=""
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-icon-only"
                                        onClick={e => deleteFile(index, e)}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col className="text-right">
                    {process ? (
                      <>
                        <Button color="primary" type="button">
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Loading...</span>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => {
                            props.showTable();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          onClick={editPost}
                        >
                          Update
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};

export default AddDic;
