/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/*eslint-disable*/
// react library for routing
// reactstrap components
import React from "react";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

const Index = props => {
  React.useEffect(() => {
    props.history.push('/auth/login');
  } , []);
  return (
    <>
      <IndexNavbar />
      <div className="main-content">
        
      </div>
      <AuthFooter />
    </>
  );
}

export default Index;
