/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { register, getUser, confirmReset } from "network/ApiAxios";
import Select2 from "react-select2-wrapper";

const AddUser = props => {
  const cuID = React.useRef(props.userID);

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailAddressFocus, setemailAddressFocus] = React.useState(false);
  const [passwordFocus, setpasswordFocus] = React.useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = React.useState(false);

  const [toastMessage, setToastMessage] = React.useState(
    "Email sent! Check it to confirm your account. "
  );
  const [userID, setUserID] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [accountStatus, setAccountStatus] = React.useState("");
  const [emailAddress, setemailAddress] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [isTester, setIsTester] = React.useState(false);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const { userType } = user;
      if (userType !== "admin") props.history.push("/admin/dashboard");
    } else {
      if (cuID) {
        const runAsync = async () => {
          const { data: info } = await getUser(cuID);
          setUserID(info.user._id);
          setName(info.user.name);
          setEmail(info.user.email);
          setRole(info.user.userType);
          setAccountStatus(info.user.accountConfirmation);

          if (info.user.userType !== "admin") {
            setIsTester(true);
          }
        };
        runAsync();
      }
    }
  }, [setUserID, setName, setEmail, setRole, setAccountStatus, props.history]);

  const handleCheck = e => {
    setAccountStatus(e.target.checked);
  };

  const registerUser = async () => {
    if (!(name && emailAddress && password && confirmPassword)) {
      setError(
        "Make sure to fill all the inputs and agree to the privacy policy"
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    const activation = true;
    const response = await register(
      name,
      emailAddress,
      password,
      role,
      activation
    );
    const { data } = response;
    if (!data.success) {
      setError(data.msg);
      return;
    }
    setError("");
    setName("");
    setemailAddress("");
    setpassword("");
    setConfirmPassword("");
    props.notify("success", "Success!", toastMessage);
  };
  const editUser = async () => {
    const response = await editUser(userID, name, email, role, accountStatus);
    const { data } = response;
    if (data.success) {
      props.notify("success", "Succes", toastMessage);
      /*  user = {...user, name, email}
        localStorage.setItem("user", JSON.stringify(user));
        props.history.push("/admin/users"); */
    } else {
      setToastMessage(data.msg);
      props.notify("danger", "Error!", toastMessage);
    }
  };
  const changePassword = async () => {
    if (password !== confirmPassword) {
      setToastMessage("Passwords do not match");
      props.notify("danger", "Error!", toastMessage);
      return;
    }
    const response = await confirmReset(userID, password);
    const { data } = response;
    if (data.success) {
      props.notify("success", "Succes", toastMessage);
      /*  user = {...user, name, email}
        localStorage.setItem("user", JSON.stringify(user));
        props.history.push("/admin/users"); */
    } else {
      setToastMessage(data.msg);
      props.notify("danger", "Error!", toastMessage);
    }
  };

  return (
    <>
      {alert}
      {userID === "" ? (
        <>
          <Container className="mt--6" fluid>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">Register a new user</h3>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: nameFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-user" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Name"
                                  type="text"
                                  onFocus={e => setNameFocus(true)}
                                  onBlur={e => setNameFocus(false)}
                                  onChange={e => setName(e.target.value)}
                                  value={name}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: emailAddressFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-envelope" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Email address"
                                  type="email"
                                  onFocus={e => setemailAddressFocus(true)}
                                  onBlur={e => setemailAddressFocus(false)}
                                  onChange={e =>
                                    setemailAddress(e.target.value)
                                  }
                                  value={emailAddress}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Select2
                                className="form-control"
                                defaultValue="1"
                                options={{
                                  placeholder: "Select",
                                }}
                                data={[
                                  { id: "admin", text: "admin" },
                                  { id: "creator", text: "creator" },
                                  { id: "member", text: "member" },
                                ]}
                                value={role}
                                onSelect={e => setRole(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: passwordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Password"
                                  type="password"
                                  onFocus={e => setpasswordFocus(true)}
                                  onBlur={e => setpasswordFocus(false)}
                                  onChange={e => setpassword(e.target.value)}
                                  value={password}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: confirmPasswordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Confirm Password"
                                  type="password"
                                  onFocus={e => setConfirmPasswordFocus(true)}
                                  onBlur={e => setConfirmPasswordFocus(false)}
                                  onChange={e =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  value={confirmPassword}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {error ? (
                          <div className="text-muted font-italic">
                            <small>
                              error:{" "}
                              <span className="text-red font-weight-700">
                                {error}
                              </span>
                            </small>
                          </div>
                        ) : null}
                        <div className="text-muted">
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() => {
                              props.showTable();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className=""
                            color="info"
                            type="button"
                            onClick={registerUser}
                          >
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <Container className="mt--6" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Edit profile</h3>
                      </Col>
                      {isTester && (
                        <Col xs="8">
                          <h5>
                            You are not allowed to edit the test user. Create
                            another user to test this functionality
                          </h5>
                        </Col>
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        User information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Name
                              </label>
                              <Input
                                value={name}
                                onChange={e => setName(e.target.value)}
                                onFocus={e => setNameFocus(true)}
                                onBlur={e => setNameFocus(false)}
                                id="input-username"
                                placeholder="Username"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                              </label>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: emailAddressFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-envelope" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Email address"
                                  type="email"
                                  onFocus={e => setemailAddressFocus(true)}
                                  onBlur={e => setemailAddressFocus(false)}
                                  onChange={e => setEmail(e.target.value)}
                                  value={email}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />

                      <h6 className="heading-small text-muted mb-4">
                        Account Status
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-role"
                              >
                                Role
                              </label>
                              <Select2
                                id="input-role"
                                className="form-control"
                                defaultValue="1"
                                options={{
                                  placeholder: "Select",
                                }}
                                data={[
                                  { id: "admin", text: "admin" },
                                  { id: "creator", text: "creator" },
                                  { id: "member", text: "member" },
                                ]}
                                value={role}
                                onSelect={e => setRole(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-role"
                              >
                                Account is active
                              </label>
                              <br></br>

                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  className="custom-control-input"
                                  id="customCheck2"
                                  type="checkbox"
                                  onChange={handleCheck}
                                  checked={accountStatus}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck2"
                                >
                                  Active
                                </label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col className="text-right" xs="12">
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => {
                            props.showTable();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={editUser}
                          size="md"
                          disabled={isTester}
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Change Password</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Password
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: passwordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Password"
                                  type="password"
                                  onFocus={e => setpasswordFocus(true)}
                                  onBlur={e => setpasswordFocus(false)}
                                  onChange={e => setpassword(e.target.value)}
                                  value={password}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: confirmPasswordFocus,
                                })}
                              >
                                <Input
                                  placeholder="Confirm Password"
                                  type="password"
                                  onFocus={e => setConfirmPasswordFocus(true)}
                                  onBlur={e => setConfirmPasswordFocus(false)}
                                  onChange={e =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  value={confirmPassword}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-eye" />
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col className="text-right" xs="12">
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => {
                            props.showTable();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={changePassword}
                          size="md
                          "
                          disabled={isTester}
                        >
                          Change
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default AddUser;
