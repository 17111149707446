/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import axios from 'axios';
import config from "config";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (conf) => {
    const token = localStorage.getItem('token');
    conf.headers.Authorization = (token ? token : '');
    conf.headers.ContentType = 'application/json';
    return conf;
});

export const getAll = async () => (
    await instance.post('users/all')
);

export const getUsers = async (limit, page, field, order, search) => (
    await instance.post('users/get', {limit,page,field,order, search})
);

export const register = async (name, email, password, userType, activation) => (
    await instance.post('users/register', {name, email, password, userType,activation})
);

export const editUser = async (userID, name, email, userType, activation) => (
    await instance.post('users/edit', {userID, name, email, userType,activation})
);

export const deleteUser = async id => (
    await instance.post(`users/delete/`,{id})
);

export const confirmRegister = async id => (
    await instance.post(`users/confirm/${id}`)
);

export const forgotPassword = async email => (
    await instance.post('users/forgotpassword', {email})
);

export const confirmReset = async (id, password) => (
    await instance.post(`users/resetpass/${id}`, {password})
);

export const getUser = async (id) => (
    await instance.post(`users/getuser/${id}`)
);

export const login = async (email, password) => (
    await instance.post('users/login', {email, password})
);

export const logout = async token => (
    await instance.post('users/logout', {token})
);



export const checkSession = async () => (
    await instance.post('users/checkSession')
);

//customers


export const getAllCustomerAll = async () => (
    await instance.post('customer/all')
);

export const getCustomers = async (limit, page, field, order) => (
    await instance.post('customer/get', {limit,page,field,order})
);

export const registerCustomer = async (name, email, password, phone , affID ,userType) => (
    await instance.post('customer/register', {name, email, password, phone, affID, userType})
);

export const editCustomer = async (customerID, name, email, password, phone , affID ,userType) => (
    await instance.post('customer/edit', {customerID, name, email, password, phone , affID ,userType})
);

export const deleteCustomer = async id => (
    await instance.post(`customer/delete/`,{id})
);

export const confirmRegisterCustomer = async id => (
    await instance.post(`customer/confirm/${id}`)
);

export const forgotPasswordCustomer = async email => (
    await instance.post('customer/forgotpassword', {email})
);

export const confirmResetCustomer = async (id, password) => (
    await instance.post(`customer/resetpass/${id}`, {password})
);

export const getCustomer = async (id) => (
    await instance.post(`customer/getuser/${id}`)
);

export const loginCustomer = async (email, password) => (
    await instance.post('customer/login', {email, password})
);

export const logoutCustomer = async token => (
    await instance.post('customer/logout', {token})
);

export const checkSessionCustomer = async () => (
    await instance.post('customer/checkSession')
);

//Dic
export const addDic = async (OwnerID, dicType, dicString, transString, dicMeaning, transMeaning, dicImage, dicVideo, publishedAt) => (
    await instance.post('dic/add', {OwnerID, dicType, dicString, dicMeaning, transString, transMeaning,dicImage,dicVideo, publishedAt})
);

export const editDic = async (id, OwnerID, dicType, dicString, transString, dicMeaning, transMeaning, dicImage, dicVideo, publishedAt) => (

    await instance.post('/dic/edit', {id, OwnerID,dicType, dicString, dicMeaning, transString, transMeaning,dicImage,dicVideo, publishedAt })
);

export const deleteDic = async id => (
    await instance.post(`dic/delete/`,{id})
);

export const getDic = async (id) => (
    await instance.post(`/dic/one/${id}`)
);

export const getDics = async (limit, page, field, order, search) => (
    await instance.post('dic/get', {limit,page,field,order,search})
);

export const getDicAll = async () => (
    await instance.post('dic/all')
);



export const uploadPics = async (file) => {
  return await instance.post('dic/uploadfile',file)
};
export const deletePics = async (keyimage) => {
    return await instance.post('dic/deletefile',{keyimage})
  };